.speaker-card {
    perspective: 1000px;
}

// .speaker-card-inner {
//     display: block;
//     position: relative;
//     width: 100%;
//     height: 100%;

//     transition: transform 0.8s;
//     transform-style: preserve-3d;
//     transform: none;

//     text-align: center;

//     cursor: pointer;
// }

// .speaker-card-front,
// .speaker-card-back {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     -webkit-backface-visibility: hidden; /* Safari */
//     backface-visibility: hidden;

//     display: flex;
//     flex-direction: column;
//     justify-content: center;
    
//     padding: 1em;
//     box-sizing: border-box;

//     border-radius: 0.5em;
// }

// .speaker-card-front {
//     > .front-style {
//         display: flex;
//         flex-direction: row;
//         justify-content: space-between;
//         width: 100%;
//         height: 100%;

//         > .flex-left {
//             width: 50%;
//             display: flex;
//             justify-content: center;
//             align-items: center;

//             > .major-speaker {
//                 text-align: center;
//             }
//         }

//         > .flex-right {
//             width: 50%;
//             display: flex;
//             justify-content: center;

//             > span {
//                 > img {
//                     display: flex;
//                     position: relative;
//                     overflow: hidden;
//                     object-fit: cover;
//                     width: 12em;
//                     height: 12em;
//                     border-radius: 100%;
//                 }
//             }
//         }
//     }
//     background: rgba($color: #FBF9E3, $alpha: 0.8);
//     text-align: left;
//     font-family: "crimson-pro";
//     font-style: italic;
    
//     color: #000000;
//     border-radius: 1em;
// }
  
// .speaker-card-back {
//     background: rgba($color: #FBF9E3, $alpha: 0.8);
//     color: #000000;
//     transform: rotateY(180deg);
//     font-size: 0.8em;
//     text-align: left;
    
//     > .minor {
//         font-size: 1.5em;
//         text-align: center;
//     }
// }
  
// @media screen and (max-width: 600px) {
//     .speaker-card {
//         .speaker-card-back {
//             font-size: 0.9em;
//         }
//     }
// }