#navbar {
    display: block;
    position: fixed;
    width: 100%;
    height: 5em;
    z-index: 5;

    transition: 0.4s all ease;

    color: #000000;

    a {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
        transition: 0.1s all ease;

        &:hover {
            filter: brightness(0.4);
        }
        &:active {
            transform: scale(0.95);
        }
    }

    > .navbar-wrapper {
        display: flex;
        position: relative;
        height: 100%;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;

        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        > .navbar-logo {
            display: flex;
            position: relative;
            height: 100%;
            width: fit-content;

            flex-direction: row;
            justify-content: center;
            align-items: center;

            > img {
                display: block;
                position: relative;
                width: auto;
                height: 80%;
            }
        }

        > .navbar-flex {
            display: flex;
            position: relative;
            height: 100%;

            flex-grow: 1;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            > .nav-menuitem {
                color: #595455;
                display: block;
                position: relative;
                width: fit-content;
                height: fit-content;
                font-size: 1.2em;

                font-weight: 700;
                padding: 10px 20px;
            }
        }
    }
}

._mobile-navbar-bg {
    > .navbar-flex {
        background: rgba($color: #FBF9E3, $alpha: 0.4);
    }
}

._navbar-bg {
    background: rgba($color: #FBF9E3, $alpha: 0.4);
}

._navbar-fixed {
    position: fixed;
    top: 0;
}

._navbar-start {
    position: absolute;
    top: 4em;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

#mobile-navbar {
    display: block;
    position: fixed;
    width: 100%;
    height: 5em;
    z-index: 5;
    font-size: 16px;

    color: #000000;

    transition: 0.3s all ease;

    > .navbar-flex {
        display: flex;
        position: relative;
        width: 100%;
        height: 4.5em;
        z-index: 5;

        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    > .nav-hamburger {
        display: flex;
        position: absolute;
        height: 4em;
        width: 4em;
        margin: 0.5em;
        top: 0;
        right: 0;
        flex-flow: column;
        justify-content: center;

        transform: translateY(-0.25em);
        transition: 0.3s all ease;

        z-index: 7;

        > .hamburger-line {
            display: block;
            position: relative;
            height: 3px;
            width: 50%;
            margin: 0.2em auto;
            border-bottom: 3px solid;
            border-color: rgb(0, 0, 0);
            border-radius: 2px;
            transition: 0.3s all ease;
        }
    }

    > .mobile-navbar-clickoff {
        display: none;
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        opacity: 0;
    }

    > .mobile-navbar-menu {
        display: block;
        position: fixed;
        height: fit-content;
        width: fit-content;

        background-color: rgba($color: #ededed, $alpha: 0.7);

        color: rgb(0, 0, 0);
        opacity: 0;

        margin: 10px;
        border-radius: 15px;

        animation: fadein 0.5s ease;

        top: -2000px;
        right: 0;
        z-index: 6;

        transition: 0.3s opacity ease;
        overflow: hidden;

        > .nav-menu-flex {
            display: flex;
            position: relative;
            height: fit-content;
            width: fit-content;

            padding-top: calc(5em - 20px);

            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            z-index: 1;

            > .nav-menuitem {
                display: block;
                position: relative;
                width: fit-content;
                min-width: 280px;

                height: fit-content;
                text-align: center;
                font-size: 1.4em;
                font-weight: 500;

                padding: 10px 5px;

                text-decoration: none;

                color: inherit;

                transition: 0.3s ease all;
                background: rgba($color: #efefef, $alpha: 0.2);

                border-top: 1px solid rgba($color: #000000, $alpha: 0.3);


                &:hover {
                    // filter: invert(0.4);
                    background-color: rgba($color: #d19f0b, $alpha: 0.3);
                }
                &:active {
                    transform: scale(0.95);
                }
            }
        }
    }
}

#hamburger-checkbox {
    display: none;
    &:checked ~ .nav-hamburger {
        transform: none;
        > .hamburger-line {
            border-color: rgb(255, 255, 255);
        }
        > .hamburger-top {
            transform: translateY(calc(0.5em + 1px)) rotate(45deg);
        }
        > .hamburger-middle {
            opacity: 0;
        }
        > .hamburger-bottom {
            transform: translateY(calc(-0.5em - 1px)) rotate(-45deg);
        }
    }

    &:checked ~ .mobile-navbar-clickoff {
        display: block;
    }

    &:checked ~ .nav-hamburger ~ .mobile-navbar-menu {
        top: 0;
        opacity: 1;
    }
}

@media only screen and (max-width: 600px) {
    #mobile-navbar {
        font-size: 12px;
    }

    ._navbar-start {
        position: absolute;
        top: 5em;
    }
}
