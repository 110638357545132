html {
    scroll-behavior: smooth;
}

.home-page {
    background-color: #fff8df;

    display: flex;
    position: relative;
    height: fit-content;
    width: 100%;

    flex-direction: column;
    overflow-x: hidden;
    justify-content: flex-start;

    font-size: 16px; // 1400px width or larger

    color: #000000;

    a {
        color: inherit;
    }

    > .travel-reimbursement-banner {
        display: flex;
        position: relative;
        height: 4em;
        background-color: #ffcc00;
        text-align: center;
        color: #333;
        font-size: 16px;
        font-weight: bold;
        align-items: center;
        z-index: 4;

        > .text {
            margin: 0 auto;
        }
    }
}

.waterfall-animation {
    display: block;
    position: absolute;
    right: 4em;
    bottom: -1em;
    z-index: 0;
}

.cloud {
    background-blend-mode: multiply;
    display: block;
    position: absolute;
    left: 4em;
    bottom: 4em;
}

.prizes-section {
    display: flex;
    position: absolute;
    top: 150px;
    height: 5em;
    width: fit-content;
  
    z-index: 10;

    > .prizes-text {
        display: block;
        position: relative;
        height: fit-content;
        width: 100%;

        text-align: left;
        font-size: 1.2em;
        margin: 0.5em 0;
        font-weight: 500;
        line-height: 0.9em;
        color: rgba(0, 0, 0, 0.8);
    }
    
    > .prizes-link {
        display: block;
        position: relative;
        width: 100px;
        height: auto;
        pointer-events: auto;

        > span {
            width: 100%;
            height: auto;

            > img {
                display: block;
                position: relative;
                width: 100%;
                height: auto;
            }
        }
    }
}

.mlh-section {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 5em;
    width: 100%;
    margin: 0 auto;
  
    z-index: 6;
    pointer-events: none;
    
    > .mlh-wrapper {
        display: flex;
        position: relative;
        height: 100%;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
    
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
  
        > .mlh-badge {
            display: block;
            position: relative;
            width: 100px;
            height: auto;
            pointer-events: auto;

            > span {
                width: 100%;
                height: auto;

                > img {
                    display: block;
                    position: relative;
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

#hero {

    z-index: 1;

    > a {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
        z-index: 10;
    }

    .hero-flex {
        display: flex;
        position: relative;
        width: 100%;
        height: fit-content;
    
        flex-direction: column;
        justify-content: center;
        align-items: center;
  
        > .flex-center {
            display: flex;
            position: relative;
            height: calc(100vh - 15em);
            width: 100%;
    
            flex-direction: column;
            justify-content: center;
            align-items: center;
    
            color: #000000;

            > .scene-container > .rotating-spline {
                width: 100%;
                height: 100%;
                animation: rotateX 10s infinite linear;
            }

            > .flex-overlay {
                display: flex;
                flex-direction: column;
                position: absolute;

                justify-content: center;

                width: 100%;
                height: 10%;

                > .hero-major {
                    display: block;
                    position: relative;
                    height: fit-content;
                    width: 100%;
                    text-align: center;
                    font-size: 6.4em;
                    font-weight: 700;
                    line-height: 0.9em;
                    letter-spacing: -10px;
                    font-family: "futura";
                    margin: 0 auto;
                    color: #000000;
                    transform: scaleY(1.2); // Adjust the value as needed
                    margin-bottom: 0.1em;
                    z-index: 1;
                }
    
                > .hero-minor {
                    display: block;
                    position: relative;
                    height: fit-content;
                    width: 100%;

                    text-align: center;
                    font-size: 3.4em;
                    font-weight: 400;
                    line-height: 0.9em;
                    color: #1D1918;
                    margin: 0 auto;
                    z-index: 1;
                }
    
                > .hero-date {
                    display: block;
                    position: relative;
                    height: fit-content;
                    width: 100%;

                    text-align: center;
                    font-size: 1.6em;
                    margin: 0.5em 0;
                    font-weight: 500;
                    line-height: 0.9em;
                    color: rgba(0, 0, 0, 0.8);
                    z-index: 1;
                }
    
                > .flex-row {
                    display: flex;
                    position: relative;
                    width: 100%;
                    height: fit-content;
    
                    justify-content: center;
                    z-index: 4;
    
                    > .hero-button {
                        display: flex;
                        justify-content: center;
                        text-decoration: none;
                        z-index: 4;
        
                        position: relative;
                        height: fit-content;
                        width: fit-content;
                
                        font-size: 1.4em;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.8);
                
                        padding: 8px 30px 12px 30px;
                        border: 3px solid rgba(0, 0, 0, 0.8);
                        border-radius: 10px;
                        transition: 0.3s all ease;
        
                        &:active {
                            filter: brightness(0.8);
                        }
                    }
                }
            }
        }

        > .presented-by-flex {
            display: flex;
            position: relative;
            height: fit-content;
            width: 100%;
            justify-content: center;
            align-items: center;
            gap: 1em;
            margin-bottom: 3em;

            > .presented-by-text {
                font-style: italic;
                font-weight: 300;
                margin: 0;
            }

            > .presented-by-imgs {
                > .dsgt-logo {
                    width: 20em;
                }

                > .sx-logo {
                    width: 10em;
                    object-fit: contain;
                }
            }
        }
    }
}

@keyframes hover {
    0% {
        transform: translateY(45px);
    }
  
    50% {
        transform: translateY(55px);
    }
    
    100% {
        transform: translateY(45px);
    }
}

.continue-reading {
    display: flex;
    position: relative;
    height: fit-content;
    width: fit-content;
    flex-direction: column;
    margin: 0 auto;
    float: right;

    justify-content: flex-start;
    align-items: center;

    animation: hover 5s infinite;

    text-decoration: none;
    line-height: 1em;

    &:hover {
        animation-play-state: paused;
        > .text {
            text-decoration: underline;
        }
    }

    > .text {
        display: block;
        position: relative;
        width: 100%;
        height: auto;
    }
}

#about {
    background-image: url("../../Assets/Backgrounds/about2.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .split-flex {
        display: flex;
        position: relative;
        height: fit-content;
        width: 60%;

        flex-direction: row;

        justify-content: center;

        gap: 1.5em;
  
        > .major {
            display: block;
            position: relative;
            height: fit-content;
            width: 50%;

            font-size: 7em;
            text-align: center;
            font-weight: 900;
            line-height: 1.3em;
            font-family: "crimson-pro";

            margin: 30px 0;
        }

        > .mini {
            margin-top: 6em;
            width: 50%;
            height: 100%;
            overflow: hidden;
            font-size: 1em;
            text-align: left;
            line-height: 1.5em;
        }
    }
}

#whenwherewhatis {
    background-image: url("../../Assets/Backgrounds/whenwhere2.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .split-flex {
        display: flex;
        position: relative;
        height: fit-content;
        width: 90%;

        flex-direction: row;
        margin-right: 2em;

        justify-content: center;

        gap: 2em;

        > .whenwhere-flex {
            display: flex;
            position: relative;
            width: 55%;
            height: fit-content;

            flex-direction: column;

            align-items: flex-start;
            justify-content: center;
            text-align: left;
            font-family: "crimson-pro";

            > .mini {
                display: block;
                position: relative;
                height: fit-content;
                width: 100%;

                font-size: 2.4em;
                font-weight: 900;
                line-height: 1.3em;
                font-style: italic;

                margin: 0 auto;
            }

            > .yetanotherflex {
                display: flex;
                position: relative;
                height: fit-content;
                width: 100%;

                flex-direction: row;
                align-items: center;

                > .flex-left {
                    width: 12em;
                    height: 12em;
                    overflow: hidden;
                    border-radius: 1em;
                }

                > .flex-right {
                    padding: 1em;
                    display: flex;
                    position: relative;
                    height: fit-content;
                    width: 60%;

                    flex-direction: column;

                    > .major {
                        display: block;
                        position: relative;
                        height: fit-content;
                        width: 100%;
        
                        font-size: 2.6em;
                        font-weight: 200;
                        line-height: 0.9em;
        
                        margin: 0 auto;
                    }
        
                    > .location {
                        display: block;
                        position: relative;
                        height: fit-content;
                        width: 100%;
        
                        font-size: 1.3em;
                        font-weight: 200;
                        line-height: 1.3em;
        
                        &:hover {
                            color: #a88934;
                            > a {
                                cursor: pointer;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }

            > .details {
                display: block;
                position: relative;
                height: fit-content;
                width: 100%;

                font-size: 1em;
                line-height: 0.8em;
                font-style: italic;
                font-weight: 100;
            }

        }

        > .whatis-flex {
            display: flex;
            position: relative;
            height: fit-content;
            width: 30%;

            flex-direction: column;
            align-items: flex-start;

            justify-content: center;
            text-align: left;

            > .major {
                display: block;
                position: relative;
                height: fit-content;
                width: 100%;

                margin: 0 auto;

                font-size: 2.6em;
                font-weight: 900;
                line-height: 1.3em;
                font-style: italic;
                font-family: "crimson-pro";
            }

            > .mini {
                display: block;
                position: relative;
                height: fit-content;
                width: 100%;

                font-size: 1em;
                line-height: 1em;
                line-height: 1.5em;
            }
        }
    }
}

#schedule {
    background-image: url("../../Assets/Backgrounds/schedule2.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .schedule-flex {
        display: flex;
        position: relative;
        flex-direction: column;
        height: fit-content;
        width: 60%;
        justify-content: center;

        > .major {
            display: block;
            position: relative;
            height: fit-content;
            width: 100%;
    
            text-align: left;
            font-size: 5em;
            font-weight: 700;
            font-family: "crimson-pro";
            font-style: italic;
            font-weight: 900;
        }
    }
}

#tracks {
    background-image: url("../../Assets/Backgrounds/track2.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .tracks-flex {
        display: flex;
        flex-direction: row;
        position: relative;

        align-items: flex-start;
        justify-content: center;

        width: 70%;
        height: fit-content;

        color: #000000;
        flex-wrap: wrap;
        
        gap: 1.5em;

        > .major {
            display: block;
            position: relative;
            height: fit-content;
            width: 100%;
        
            text-align: center;
            font-size: 5em;
            font-family: "crimson-pro";
            font-weight: bold;
            line-height: 1.3em;
            margin: 0 auto;
            font-style: italic;
        }
    }

    .clickToFlip {
        display: block;
        position: relative;
        height: fit-content;
        width: 100%;

        font-size: 1em;
        font-weight: 400;
        letter-spacing: 1px;
        margin: 0 auto;
        margin-bottom: 1em;
    }
}

#prizes {
    background-image: url("../../Assets/Backgrounds/prizes2.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .prizes-flex {
        display: flex;
        position: relative;
        flex-direction: column;
        flex-wrap: wrap;

        height: fit-content;
        width: 80%;

        margin: 0 auto;
        color: #000000;
        
        justify-content: center;
        align-items: center;

        gap: 1em;

        > .major {
            display: block;
            position: relative;
            height: fit-content;
            width: 100%;
    
            text-align: center;
            font-size: 5em;
            font-weight: 700;
            line-height: 1.3em;
            font-family: "crimson-pro";
            margin: 0 auto;
            font-style: italic;
        }
    
        > .mini {
            display: block;
            position: relative;
            height: fit-content;
            width: 60%;
    
            font-size: 1em;
            font-weight: 400;
            letter-spacing: 1px;
            margin: 0 auto;
            margin-bottom: 2em;
        }

        > .prize-grid {
            display: flex;
            position: relative;
            flex-direction: row;
            flex-wrap: wrap;

            height: fit-content;
            width: 100%;

            justify-content: center;
            align-items: end;
            gap: 1em;

            > .prize-card-event {
                display: block;
                position: relative;
                height: fit-content;
                width: 20%;
                mix-blend-mode: multiply;

                > span {
                    width: 100%;
                    height: 100%;

                    > img {
                        display: block;
                        position: relative;
                        width: 60%;
                        margin: 0 auto;
                    }
                }

                > .prize-major {
                    display: block;
                    position: relative;
                    height: fit-content;
                    width: 100%;

                    text-align: center;
                    font-size: 1.8em;
                    font-weight: bold;
                    line-height: 1em;
                    margin: 10px 0;
                    font-style: italic;
                    font-family: "crimson-pro";
                }

                > .prize-mini {
                    display: block;
                    position: relative;
                    height: fit-content;
                    width: 100%;
                    
                    text-align: center;
                    font-size: 1em;
                    font-weight: 300;
                    line-height: 1.2em;
                    margin: 10px 0;
                    font-family: "crimson-pro";
                }
            }

            > .prize-card-first,
            > .prize-card-second,
            > .prize-card-third {
                mix-blend-mode: multiply;
                display: block;
                position: relative;
                height: fit-content;

                > span {
                    width: 100%;
                    height: 100%;

                    > img {
                        display: block;
                        position: relative;
                        width: 60%;
                        margin: 0 auto;
                    }
                }

                > .prize-major {
                    display: block;
                    position: relative;
                    height: fit-content;
                    width: 100%;

                    text-align: center;
                    font-size: 1.8em;
                    font-weight: bold;
                    line-height: 1em;
                    margin: 10px 0;
                    font-style: italic;
                    font-family: "crimson-pro";
                }

                > .prize-mini {
                    display: block;
                    position: relative;
                    height: fit-content;
                    width: 100%;
                    
                    text-align: center;
                    font-size: 1em;
                    font-weight: 300;
                    line-height: 1.2em;
                    margin: 10px 0;
                    font-family: "crimson-pro";
                }
            }

            > .prize-card-first {
                width: 30%;
            }

            > .prize-card-second {
                width: 25%;
            }

            > .prize-card-third {
                width: 20%;
            }
        }
    }
}

#faq {
    background-image: url("../../Assets/Backgrounds/faq2.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    
    .faq-flex {
        display: flex;
        position: relative;
        width: 80%;
        flex-direction: row;
        flex-wrap: wrap;

        gap: 1em;

        > .major {
            display: block;
            position: relative;
            height: fit-content;
            width: 100%;
    
            text-align: left;
            font-size: 5em;
            font-weight: 700;
            line-height: 1.3em;
            font-family: "crimson-pro";
            margin: 0 auto;
            font-style: italic;
        }

        > .faq-grid {
            display: flex;
            position: relative;
            width: 100%;
            height: fit-content;

            flex-direction: row;
            flex-wrap: wrap;

            justify-content: flex-start;
            margin-left: 3em;
            gap: 1.5em 6em;
        }
    }
}

#speakers {
    background-image: url("../../Assets/Backgrounds/speakers2.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .speakers-flex {
        display: flex;
        position: relative;
        height: fit-content;
        width: 80%;

        flex-direction: column;
        margin: 0 auto;

        gap: 1em;

        > .major {
            display: block;
            position: relative;
            height: fit-content;
            width: 100%;
    
            text-align: left;
            font-size: 5em;
            font-weight: 700;
            line-height: 1.3em;
            font-family: "crimson-pro";
            font-style: italic;
        }

        > .tri-flex {
            display: flex;
            position: relative;
            width: 100%;
            min-height: 30vh;
            
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;

            gap: 2em;

            > .speaker-card {
                display: block;
                position: relative;
                width: 40%;
                min-width: 200px;
                max-width: calc(100% - 6em);
                height: fit-content;
                padding-top: 0;

                justify-content: center;

                > .speaker-bg {
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: calc(100% - 70px);
                    bottom: 0;
                    left: 0;
                    z-index: -1;
          
                    background: rgba(217, 217, 217, 0.205);
                    border-radius: 3em;
                  }

                > span {
                    width: 100%;
                    height: 100%;
                    > img {
                        display: block;
                        position: relative;
                        width: 12em;
                        height: 12em;
                        object-fit: cover;
                        margin: 0 auto;
                        border-radius: 100%;
                    }
                }

                > .speaker-major {
                    display: block;
                    position: relative;
                    height: fit-content;
                    width: 100%;

                    text-align: center;
                    font-size: 1.6em;
                    font-family: "crimson-pro";
                    font-weight: 700;
                    line-height: 1.3em;
                    font-style: italic;

                    > a {
                        text-decoration: none;
                        cursor: pointer;

                        &:hover {
                            color: rgb(0, 0, 0, 0.5);
                            text-decoration: underline;
                        }
                    }
                }

                > .speaker-mini {
                    display: inline-block;
                    position: relative;
                    height: fit-content;
                    width: 80%;
                    margin: 0 auto;
                    
                    justify-content: center;
                    text-align: left;
                    font-size: 1em;
                    font-weight: 300;
                    line-height: 1.2em;
                    padding-bottom: 2em;
                }
            }
        }
    }

    .tba {
        display: flex;
        position: absolute;
        width: 100%;
        height: 70%;
        align-items: center;
        
        backdrop-filter: blur(5px); // Adjust the blur radius as needed
        bottom: 0%;
        > .major {
            margin: 0;
            text-align: center;
            font-size: 3em;
        }
    }
}

#team {
    background-image: url("../../Assets/Backgrounds/team2.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .team-flex {
        display: flex;
        position: relative;
        width: 82%;
        height: fit-content;

        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 0 auto;

        > .team-grid {
            display: flex;
            position: relative;
            width: 100%;
            height: fit-content;

            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;

            margin-top: 1em;

            gap: 2em 2.5em;
            padding: 1em;

            > .team-member-card {
                display: flex;
                position: relative;
                width: 22%;
                height: 12em;

                align-items: flex-start;
                text-align: left;

                > .img-container {
                    display: flex;
                    position: relative;
                    width: 50%;
                    height: fit-content;

                    flex-direction: row;
                    margin-right: 10%;
                    
                    > span {
                        display: flex;
                        position: relative;
                        width: 100%;
                        height: fit-content;

                        > img {
                            display: flex;
                            position: relative;
                            overflow: hidden;
                            object-fit: cover;
                            width: 8em;
                            height: 8em;
                            border-radius: 100%;
                        }
                    }
                }
                
                > .nametitle {
                    display: flex;
                    position: relative;
                    width: 50%;
                    height: fit-content;
                    line-height: 1.3em;

                    flex-direction: column;
                    margin: 0;

                    > a {
                        text-decoration: none;
                        cursor: pointer;

                        &:hover {
                            color: rgb(0, 0, 0, 0.5);
                            text-decoration: underline;
                        }
                        > .name {
                            font-family: "crimson-pro";
                            font-size: 1.7em;
                            font-weight: bold;
                            line-height: 1em;
                            margin: 0;
                        }
                    }
    
                    > .title {
                        font-size: 1.1em;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .major {
        display: block;
        position: relative;
        width: 100%;
        height: fit-content;

        font-family: "crimson-pro";
        font-size: 5em;
        text-align: left;
        line-height: 1.3em;
        font-style: italic;

        margin-top: 1em 0;
        margin-bottom: 0;
    }

    .mini {
        display: block;
        position: relative;
        width: 100%;
        height: fit-content;

        text-align: left;
        font-size: 1.2em;
        font-weight: 300;
        line-height: 1.2em;
    }
}

#sponsors {
    padding: 3em 0;
    background-color: #1D1918;

    .pastsponsors-flex {
        display: flex;
        position: relative;
        width: 80%;
        height: fit-content;
        margin: 0 auto;

        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        > .left {
            justify-content: flex-start !important;
        }

        > .major {
            display: block;
            position: relative;
            width: 100%;
            height: fit-content;
    
            color: #fff;
    
            font-size: 5em;
            font-weight: bold;
            text-align: left;
            line-height: 1.3em;
            font-family: "crimson-pro";
            font-style: italic;
        }

        > .sponsors-flex {
            display: flex;
            position: relative;
            height: fit-content;
            width: 100%;

            justify-content: space-evenly;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 1em;

            > .sponsor {
                display: block;
                position: relative;
                height: 12em;
                width: 12em;
                padding: 0.5em;
                
                > span {
                    display: flex !important;
                    position: relative;
                    width: 100%;
                    height: 100%;

                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    > img {
                        display: block;
                        position: relative;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }

            > .sponsor-large {
                display: block;
                position: relative;
                height: 12em;
                width: 16em;
                padding: 0.5em;
                
                > span {
                    display: flex !important;
                    position: relative;
                    width: 100%;
                    height: 100%;

                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    > img {
                        display: block;
                        position: relative;
                        width: 16em;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }

            > .sponsor-larger {
                display: block;
                position: relative;
                height: 12em;
                width: 20em;
                padding: 0.5em;
                
                > span {
                    display: flex !important;
                    position: relative;
                    width: 100%;
                    height: 100%;

                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    > img {
                        display: block;
                        position: relative;
                        width: 20em;
                    }
                }
            }

            > .sponsor-largerer {
                display: block;
                position: relative;
                height: 12em;
                width: 24em;
                padding: 0.5em;
                
                > span {
                    display: flex !important;
                    position: relative;
                    width: 100%;
                    height: 100%;

                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    > img {
                        display: block;
                        position: relative;
                        width: 24em;
                    }
                }
            }

            > .sponsor-largest {
                display: block;
                position: relative;
                height: 12em;
                width: 45em;
                padding: 0.5em;
                
                > span {
                    display: flex !important;
                    position: relative;
                    width: 100%;
                    height: 100%;

                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    > img {
                        display: block;
                        position: relative;
                        width: 45em;
                    }
                }
            }

            > .sponsor-small {
                display: block;
                position: relative;
                height: 12em;
                width: 10em;
                padding: 0.5em;
                
                > span {
                    display: flex !important;
                    position: relative;
                    width: 100%;
                    height: 100%;

                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    > img {
                        display: block;
                        position: relative;
                        width: 10em;
                    }
                }
            }

            > .sponsor-smaller {
                display: block;
                position: relative;
                height: 12em;
                width: 8em;
                padding: 0.5em;
                
                > span {
                    display: flex !important;
                    position: relative;
                    width: 100%;
                    height: 100%;

                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    > img {
                        display: block;
                        position: relative;
                        width: 8em;
                    }
                }
            }
        }
    }

    .minor {
        display: block;
        position: relative;
        width: 100%;
        height: fit-content;

        color: #fff;

        font-size: 4em;
        font-weight: bold;
        text-align: left;
        line-height: 1.3em;
        font-family: "crimson-pro";
        font-style: italic;
    }
}

@media only screen and (max-width: 1400px) {
    .home-page {
        font-size: 14px;
    }
}

@media only screen and (max-width: 1300px) {
    .home-page {
        font-size: 13px;
    }
}

@media only screen and (max-width: 1200px) {
    .mlh-section > .mlh-wrapper {
        justify-content: flex-start;
        & > .mlh-badge {
            width: 60px;
            margin: 0 10px;
        }
    }
}

@media only screen and (max-width: 1000px) {

    .home-page {
        > .travel-reimbursement-banner {
            > .text {
                
            }
        }
    }

    .continue-reading {
        display: none;
    }

    #team {
        .team-flex {
            > .team-grid {
                gap: 1em 2em;
                > .team-member-card {
                    gap: 1em;
                    > .nametitle {
    
                        a {
                            > .name {
                                font-size: 1.4em;
                            }
                        }
        
                        > .title {
                            font-size: 0.9em;
                        }
                    }
                }
            }
        }
    }
}
    
// mobile
@media only screen and (max-width: 600px) {

    .home-page {
        > .travel-reimbursement-banner {
            display: flex;
            position: relative;
            height: 3.5em;
            background-color: #ffcc00;
            text-align: center;
            color: #333;
            font-size: 16px;
            font-weight: bold;
            align-items: center;
            z-index: 4;
    
            > .text {
                margin: 0.5em auto;
                font-size: 0.8em;
                width: 60%;
            }
        }
    }

    .flex-center {
        display: flex;
        position: relative;
        height: fit-content;
        width: 100%;

        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .scene-container {
        align-items: flex-start;
        position: relative;
        > .videoTag {
            width: 700px;
        }
    }

    .home-page {
        font-size: 12px;
    }

    #hero {
        .hero-flex {
            display: flex;
            position: relative;
            width: 100%;
            height: fit-content;
        
            flex-direction: column;
            justify-content: center;
            align-items: center;

            flex-wrap: none;

            > .flex-center {
                height: fit-content;
                
                > .flex-overlay {
                    position: relative;
                    height: fit-content;

                    > .hero-major {
                        display: block;
                        position: relative;
                        height: fit-content;
                        width: 100%;
                        text-align: center;
                        font-size: 5.3em;
                        font-weight: 700;
                        letter-spacing: -8px;
                        font-family: "futura";
                        margin-bottom: 0.5em;
                    }
                }
            }

            > .presented-by-flex {
                margin-top: 3em;
                display: flex;
                position: relative;
                height: fit-content;
                width: 100%;

                flex-direction: column;
                > .presented-by-imgs {
                    justify-content: center;
                }
            }
        }
    }

    #about {
        .split-flex {
            display: flex;
            position: relative;
            width: 70%;
            height: fit-content;

            flex-direction: column;

            > .major {
                width: 100%;
                text-align: left;
                font-size: 4em;
                margin: 0;
            }

            > .mini {
                width: 100%;
                margin: 0;
            }
        }
    }

    #whenwherewhatis {
        .split-flex {
            margin: 0 auto;
            display: flex;
            position: relative;
            width: 100%;
            height: fit-content;

            flex-direction: column;
            align-items: center;
            justify-content: center;

            > .whenwhere-flex {
                width: 70%;

                > .mini {
                    font-size: 2em;
                }

                > .major {
                    font-size: 4em;
                }

                > .map {
                    width: 100%;
                }
            }

            > .whatis-flex {
                width: 70%;
            }
        }
    }

    #schedule {
        .schedule-flex {
            > .major {
                font-size: 4em;
            }
        }
    }

    #tracks {
        .tracks-flex {
            > .major {
                font-size: 4em;
            }
        }
    }

    #prizes {
        .prizes-flex {
            > .major {
                font-size: 4em;
            }

            > .prize-grid {
                display: flex;
                position: relative;
                width: 100%;
                height: fit-content;

                > .prize-card-first {
                    width: 40%;
                }
    
                > .prize-card-second {
                    width: 40%;
                }
    
                > .prize-card-third {
                    width: 40%;
                }

                > .prize-card-event {
                    display: block;
                    position: relative;
                    height: fit-content;
                    width: 40%;
                    mix-blend-mode: multiply;
                    > .prize-card-third {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    #faq {
        .faq-flex {
            > .major {
                font-size: 4em;
            }

            > .faq-grid {
                display: flex;
                position: relative;
                width: 100%;
                height: fit-content;

                flex-direction: row;
                flex-wrap: wrap;
                gap: 1.5em 3em;
            }
        }
    }
    

    #speakers {
        .speakers-flex {
            > .major {
                font-size: 4em;
            }

            > .tri-flex {
                > .speaker-card {
                    width: 100%;

                    > .speaker-mini {

                    }
                }
            }
        }
    }

    #team {
        .team-flex {
            > .major {
                font-size: 4em;
            }

            > .team-grid {
                > .team-member-card {
                    width: 40%;
                    height: 10em;

                    > .img-container {
                        span {
                            img {
                                width: 6em;
                                height: 6em;
                            }
                        }
                    }

                    > .nametitle {
                        a {
                            > .name {
                                font-size: 1.4em;
                            }
                        }

                        > .title {
                            font-size: 0.9em;
                            line-height: 1.4em;
                        }
                    }
                }
                gap: 0 2em;
            }
        }
    }

    #sponsors {
        .pastsponsors-flex {
            > .sponsors-flex {
                > .sponsor-largest {
                    display: block;
                    position: relative;
                    height: 12em;
                    width: 30em;
                    padding: 0.5em;
                    
                    > span {
                        display: flex !important;
                        position: relative;
                        width: 100%;
                        height: 100%;
    
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
    
                        > img {
                            display: block;
                            position: relative;
                            width: 30em;
                        }
                    }
                }
            }
            > .major {
                font-size: 4em;
            }
        }
    }
}

