.track-card {
    display: block;
    position: relative;
    width: 40%;
    min-width: 300px;
    height: 25vh;
    min-height: 200px;

    perspective: 1000px;
    cursor: pointer;

    .card-check {
        display: none;

        &:checked ~ .track-card-inner {
            transform: rotateY(180deg);
        }
    }
}

.track-card-inner {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;

    transition: transform 0.8s;
    transform-style: preserve-3d;
    transform: none;

    text-align: center;

    cursor: pointer;
}

.track-card-front,
.track-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;
    
    padding: 1em;
    box-sizing: border-box;

    border-radius: 0.5em;
}

.track-card-front {
    > .airplane-left {
        display: block;
        position: absolute;
        left: 6px;

        > img {
            width: 20px;
            transform: rotate(270deg);
        }
    }

    > .airplane-right {
        display: block;
        position: absolute;
        right: 6px;

        > img {
            width: 20px;
            transform: rotate(90deg);
        }
    }

    > .front-style {
        display: flex;
        position: relative;

        height: 100%;
        flex-direction: column;
        justify-content: center;

        border: 2px dashed rgb(0, 0, 0, 0.4);
        border-width: 1px;

        > .major-card {
            margin: 0;
            margin-left: 1em;
        }

        > .major-card-sub {
            margin: 0;
            margin-left: 2em;
            align-items: center;
            font-size: 1em;
        }
    }
    text-align: left;
    font-family: "crimson-pro";
    font-style: italic;
    
    background: rgba($color: #FBF9E3, $alpha: 0.8);
    color: #000000;
}
  
.track-card-back {
    background: rgba($color: #FBF9E3, $alpha: 0.8);
    color: #000000;
    transform: rotateY(180deg);
    font-size: 0.8em;
    text-align: left;
    
    > .minor {
      font-size: 1.5em;
      text-align: center;
    }
}

@media screen and (max-width: 1400px) {
    .track-card-front {
        > .airplane-left {
            left: 4px;
        }
    
        > .airplane-right {
            right: 4px;
        }
    }
}

@media screen and (max-width: 1100px) {
    .track-card-front {
        > .airplane-left {
            left: 2px;
        }
    
        > .airplane-right {
            right: 2px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .track-card-front {
        > .airplane-left {
            left: 1px;
        }
    
        > .airplane-right {
            right: 1px;
        }
    }
}
  
@media screen and (max-width: 600px) {
    .track-card {
        .track-card-back {
            font-size: 0.9em;
        }
    }
}