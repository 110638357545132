.faq {
    display: block;
    position: relative;
    height: fit-content;
    width: 40%;

    font-size: 1em;

    > .airplane-left {
        display: block;
        position: absolute;
        top: 0.8em;
        left: -2.4em;

        > img {
            width: 100%;
            height: auto;
        }
    }

    a {
        color: inherit;
    }

    > .faq-checkbox {
        display: none;
    }

    > .faq-checkbox:checked + .faq-label {
        transition: 2s all ease;

        > .faq-arrow {
            transform: rotateZ(180deg);
            user-select: none;
        }
    }

    > .faq-checkbox:checked + .faq-label + .faq-answer {
        // display: block;
        height: fit-content;
        opacity: 1;
        padding: 10px;
    }

    > .faq-label {
        display: flex;
        position: relative;
        flex-direction: row;

        width: 100%;
        height: fit-content;

        padding: 1em;
        justify-content: space-between;
        align-items: center;
        gap: 0.5em;

        border-bottom: 1px dashed rgb(0, 0, 0, 0.2);

        cursor: pointer;
        > .faq-question {
            text-align: left;
            font-family: "crimson-pro";
            font-size: 1.4em;
            font-weight: 800;
        }
    
        > .faq-arrow {
            display: block;
            position: relative;
            float: right;
            margin-right: 10px;

            font-weight: 700;

            transition: 0.3s all ease;
        }
    }

    > .faq-answer {
        transition: 0.5s all ease;
        height: 0;
        opacity: 0;

        position: relative;
        width: 90%;
        // height: fit-content;
        
        font-size: 1em;
        font-weight: 600;
        color: rgb(0, 0, 0, 0.7);
        text-align: left;
        font-family: "crimson-pro";

        // padding: 10px;
        margin: 0;
        
    }
}

@media screen and (max-width: 1400px) {
    .faq {
        > .airplane-left {
            display: block;
            position: absolute;
            top: 1em;
            left: -2.4em;
    
            > img {
                width: 80%;
                height: auto;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .faq {
        > .airplane-left {
            display: block;
            position: absolute;
            top: 1.4em;
            left: -2em;
    
            > img {
                width: 60%;
                height: auto;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .faq {
        > .airplane-left {
            display: block;
            position: absolute;
            top: 1.5em;
            left: -2.3em;
    
            > img {
                width: 50%;
                height: auto;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .faq {
        > .faq-label {

            cursor: pointer;
            > .faq-question {

            }
        
            > .faq-arrow {
                display: none;
            }
        }
    }
}