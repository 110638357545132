#footer {
    display: block;
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 2em 0;

    background: #000000;

    a {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
    }

    .footer-wrapper {
        display: flex;
        position: relative;
        height: fit-content;
        width: 100%;

        max-width: 1200px;
        margin: 0 auto;
        padding: 1.5em 0;

        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        flex-wrap: wrap;

        color: #fff;

        > .footer-section {
            display: flex;
            position: relative;
            width: fit-content;
            max-width: 20%;
            height: fit-content;

            padding: 0.5em 1em;

            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            font-weight: bold;

            > .footer-header {
                display: flex;
                position: relative;
                width: 100%;
                height: fit-content;

                justify-content: flex-end;

                flex-direction: row;
                margin-bottom: 1em;

                span {
                    width: 30%;

                    > .sxlogo {
                        display: flex;
                        position: relative;
                        right: 8.5em;
                    }

                    > .dsgtlogo {
                        display: block;
                        position: relative;
                        width: 8em;
                    }
                }

                > .major {
                    margin: 0;
                }
            }

            > .footer-links {
                display: flex;
                position: relative;
                width: 100%;
                height: fit-content;

                flex-direction: column;
                float: right;
            }
        }
    }

    .major {
        display: block;
        position: relative;
        height: fit-content;
        width: fit-content;
        text-align: right;
        font-size: 2.2em;
        font-weight: 700;
        line-height: 1.2em;
    }

    .minor {
        display: block;
        position: relative;
        height: fit-content;
        width: fit-content;
        text-align: right;
        font-size: 1.5em;
        font-weight: 500;
        line-height: 1.2em;
        margin: 0;
    }

    .mini {
        display: block;
        position: relative;
        width: 100%;
        height: fit-content;
        text-align: right;

        font-size: 1.2em;
        font-weight: bold;
        line-height: 1.3em;
        transition: 0.3s all ease;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

@media screen and (max-width: 600px) {
    #footer {
        > .footer-wrapper {
            > .footer-section {
                padding: 0;
                max-width: 15%;

                > .footer-header {
                    span {
                        width: 30%;
    
                        > .sxlogo {
                            display: block;
                            position: relative;
                            width: 8em;
                            right: 70px;
                        }
    
                        > .dsgtlogo {
                            display: block;
                            position: relative;
                            width: 4em;
                            right: 8px;
                        }
                    }
                }   
            }
        }
        .minor {
            font-size: 1em;
        }
    
        .major {
            font-size: 1.3em;
        }
    
        .mini {
            font-size: 0.7em;
        }
    }
}